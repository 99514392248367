<template>
  <div id="subscription">
    <div class="columns">
      <div class="column is-6 is-offset-3 has-text-centered">
        <p class="title is-5">Your subscription has expired.</p>
        <p class="subtitle is-6">Kindly renew to continue using Simsed.</p>
        <b-button
          icon-left="dollar-sign"
          class="is-success is-fullwidth"
          @click="initializePayment()"
          >Pay</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'subscription',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Subscription Renewal',
    }
  },
  methods: {
    initializePayment() {
      this.$router.push(`/school/${this.schoolId}/subscription_payment`)
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)

    this.$store.commit('setSubMenus', [
      {
        name: 'Subscriptions',
        route: `/school/${this.schoolId}/subscriptions`,
      },
    ])
  },
}
</script>
